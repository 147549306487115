<div class="ion-margin-horizontal ion-padding-bottom">
  <h2>{{ 'auth-forgot-password' | tr }}</h2>
  <p>{{ 'auth-forgot-password-message' | tr }}</p>

  <ion-item class="ion-margin-top">
    <ion-label position="floating">{{ 'edit-txt-yourEmail' | tr }} *</ion-label>

    <ion-input
      uid="forgotPasswordModal__email__input"
      name="email"
      [formControl]="emailControl"
      required
    />
  </ion-item>
</div>

<ion-footer>
  <ion-buttons>
    <ion-button
      size="small"
      fill="outline"
      shape="round"
      uid="forgotPasswordModal__cancel"
      (click)="dismissModal()"
    >
      <ion-label>{{ 'btn-cancel' | tr }}</ion-label>
    </ion-button>

    <ion-button
      size="small"
      fill="solid"
      shape="round"
      [disabled]="!emailControl.valid"
      uid="forgotPasswordModal__confirm"
      (click)="confirm()"
    >
      <ion-label tmd="btn-confirm" />
    </ion-button>
  </ion-buttons>
</ion-footer>
