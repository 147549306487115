import { inject, Injectable } from '@angular/core'
import { EVENT } from '@app/analytics/analytics.cnst'
import { AnalyticsService } from '@app/analytics/analytics.service'
import { MixPanelService } from '@app/analytics/mixpanel.service'
import { ROUTES } from '@app/cnst/nav.cnst'
import { SNACKBAR } from '@app/cnst/snackbars.cnst'
import { loader, LoaderType } from '@app/decorators/decorators'
import { ForgotPasswordModal } from '@app/modals/forgot-password/forgot-password.modal'
import { AuthTermsPage } from '@app/pages/auth-terms/auth-terms.page'
import { di } from '@app/srv/di.service'
import { Priority } from '@app/srv/popup.cnst'
import { PopupController } from '@app/srv/popup.controller'
import { NavController } from '@ionic/angular/standalone'
import { _assert, _stringify, AppError, localTime, pTry } from '@naturalcycles/js-lib'
import {
  accountSharedUtil,
  BackendResponseFM,
  CartItemInput,
  ErrorCode,
  hwIdByProductKey,
  LoginOrRegisterInput,
  LoginProvider,
  ProductKey,
  ProductVariant,
  ShouldLoginInput,
  SocialAuthInput,
} from '@naturalcycles/shared'
import { Ab322Service } from '@src/experiments/ab322/srv/ab322.service'
import {
  AuthPageMode,
  AuthProviderResult,
  AuthProviderType,
  EmailAuthProviderResult,
  PrivacyTermsConsent,
  SocialAuthProviderInput,
  SocialAuthProviderResult,
} from '@src/typings/auth'
import { goalMap, quizParentSlug } from '../cnst/quiz.cnst'
import { isIOSApp } from '../cnst/userDevice.cnst'
import { logUtil } from '../util/log.util'
import { measuringDeviceList } from '../util/product.util'
import { AccountService } from './account.service'
import { BootstrapService } from './bootstrap.service'
import { CartService } from './cart.service'
import { LangService } from './lang.service'
import { NotificationService } from './notification.service'
import { ProductService } from './product.service'
import { QuizService } from './quiz/quiz.service'
import { RecaptchaService } from './recaptcha.service'
import { sentryService } from './sentry.service'
import { sessionSigningService } from './sessionSigning.service'
import { SnackbarService } from './snackbar.service'
import { dispatch, getState } from './store.service'
import { tr } from './translation.util'

@Injectable({ providedIn: 'root' })
export class AuthService {
  private popupController = inject(PopupController)
  private cartService = inject(CartService)
  private langService = inject(LangService)
  private accountService = inject(AccountService)
  private analyticsService = inject(AnalyticsService)
  private snackbarService = inject(SnackbarService)
  public navController = inject(NavController)
  private notificationService = inject(NotificationService)
  private recaptchaService = inject(RecaptchaService)
  private quizService = inject(QuizService)
  private mixpanelService = inject(MixPanelService)
  private productService = inject(ProductService)
  private ab322Service = inject(Ab322Service)

  public ROUTES = ROUTES

  async askPrivacyTermsConsent(partnerEmail?: string): Promise<PrivacyTermsConsent | undefined> {
    const modal = await this.popupController.presentModal(
      {
        component: AuthTermsPage,
        componentProps: {
          partnerEmail,
        },
      },
      'modal-authTerms',
      Priority.IMMEDIATE,
    )
    const { data } = await modal.onDidDismiss<PrivacyTermsConsent>()

    return data
  }

  async createOrLogin(
    result: AuthProviderResult,
    shouldEnterQuiz?: boolean,
  ): Promise<BackendResponseFM | undefined> {
    if (!result.input.accountAlreadyExists) {
      await this.setMeasuringdeviceFromQuiz()
    }

    switch (result.type) {
      case AuthProviderType.email: {
        return await this.createOrLoginWithEmail(result)
      }
      case AuthProviderType.social: {
        await this.createOrLoginWithSocialAuth(result, shouldEnterQuiz)
        return
      }
    }
  }

  private async createOrLoginWithEmail(
    result: EmailAuthProviderResult,
  ): Promise<BackendResponseFM | undefined> {
    const { userDevice, account, ui, quiz, cart } = getState()
    const recaptchaToken = await this.recaptchaService.generateRecaptchaToken('register')

    const input: LoginOrRegisterInput = {
      email: result.input.email,
      pw: result.input.pw,
      recaptchaToken,
      lang: ui.lang,
      userDevice,
      appConsent: true,
      goal: goalMap[quiz.data?.fertilityGoal!] || account.goal,
      quizData: quiz.data || undefined,
      hwId: cart.hwId,
      discountCode: cart.discount?.code,
    }

    let submitResult: BackendResponseFM | undefined

    if (result.mode === AuthPageMode.Signup && result.input.accountAlreadyExists) {
      result.mode = AuthPageMode.Login
    }

    switch (result.mode) {
      case AuthPageMode.Signup: {
        submitResult = await this.register(input, result.consent!)
        break
      }
      case AuthPageMode.Login: {
        submitResult = await this.login(input)
        break
      }
      case AuthPageMode.AnonymousModeEnter: {
        submitResult = await this.enterAnonymousMode(input)
        break
      }
      case AuthPageMode.AnonymousModeExit: {
        submitResult = await this.exitAnonymousMode(input)
        break
      }
      default: {
        throw new Error(`Unknown auth page mode: ${result.mode}`)
      }
    }
    if (result.mode === AuthPageMode.Login) {
      await this.handleLogin(submitResult)
    }

    return submitResult
  }

  private async createOrLoginWithSocialAuth(
    result: SocialAuthProviderResult,
    shouldEnterQuiz?: boolean,
  ): Promise<void> {
    const [errorSigningIn] = await pTry(
      this.socialAuth(result.input, result.consent, shouldEnterQuiz),
    )

    if (!errorSigningIn) {
      return
    }

    if (
      errorSigningIn instanceof AppError &&
      errorSigningIn.cause?.data.code === ErrorCode.ACCOUNT_EXISTS
    ) {
      sentryService.captureMessage(
        `Account already exists. Error: ${ErrorCode.ACCOUNT_EXISTS}, AuthPageMode: ${result.mode}, AuthProviderType: ${result.type}`,
        'log',
      )

      const { data } = errorSigningIn.cause.data
      return await this.linkAccountWithPassword(async params => {
        result.input.pw = params.password
        const [errorLinking] = await pTry(
          this.socialAuth(result.input, result.consent, shouldEnterQuiz),
        )

        if (errorLinking) {
          return await this.forgotPassword(data.email)
        }
      }, result.input.loginProvider)
    }

    if (result.input.loginProvider === LoginProvider.APPLE) {
      if (
        errorSigningIn instanceof AppError &&
        errorSigningIn.cause?.data.code === ErrorCode.NO_ACCOUNT
      ) {
        logUtil.log('apple.auth tried to log in with not existing Apple account')
        logUtil.error(errorSigningIn)
        void this.popupController.presentAlert(
          {
            header: tr('auth-apple-no-account-header'),
            message: tr('auth-apple-no-account-message'),
            buttons: [tr('txt-ok')],
          },
          'alert_AppleLoginError',
        )
        return
      }

      logUtil.log('apple.auth generic error')
      logUtil.error(errorSigningIn)
      const errorMessage = `<br><br><small>${_stringify(errorSigningIn)}</small>`

      void this.popupController.presentAlert(
        {
          header: tr('auth-apple-generic-failed-header'),
          message: `${tr('auth-apple-generic-failed-message')} ${errorMessage}`,
          buttons: [tr('txt-ok')],
        },
        'alert_AppleLoginError',
      )
      return
    }

    throw errorSigningIn
  }

  @loader(LoaderType.BLOCKING)
  async register(
    input: LoginOrRegisterInput,
    privacyConsent: PrivacyTermsConsent,
  ): Promise<BackendResponseFM> {
    input.plan = this.cartService.getSubscriptionType()
    input.consentTermsLanguage = privacyConsent.consentTermsLanguage
    input.marketingConsent = privacyConsent.marketingConsent
    input.marketingSocialConsent = privacyConsent.marketingSocialConsent
    input.marketingSocialConsentKey = privacyConsent.marketingSocialConsentKey
    input.marketingSocialConsentTo = privacyConsent.marketingSocialConsentTo
    input.marketingSocialConsentFromATT = privacyConsent.marketingSocialConsentFromATT
    input.publicKey ||= await sessionSigningService.getPublicKey()

    const response = await this.accountService.createOrLogin(input)

    await this.notificationService.openNotificationsModal()

    await this.handleLogin(response)

    return response
  }

  @loader(LoaderType.BLOCKING)
  async login(input: LoginOrRegisterInput): Promise<BackendResponseFM> {
    input.publicKey ||= await sessionSigningService.getPublicKey()

    dispatch('replaceUserDeviceAuth', { ...input, lastLoginTime: localTime.nowUnix() })
    const response = await this.accountService.login(input)
    dispatch('resetUserDeviceAuth')

    return response
  }

  @loader(LoaderType.BLOCKING)
  async enterAnonymousMode(input: LoginOrRegisterInput): Promise<BackendResponseFM> {
    return await this.accountService.enterAnonymousMode(input.email, input.pw)
  }

  @loader(LoaderType.BLOCKING)
  async exitAnonymousMode(input: LoginOrRegisterInput): Promise<BackendResponseFM> {
    return await this.accountService.exitAnonymousMode(input.email, input.pw)
  }

  @loader(LoaderType.BLOCKING)
  async shouldLoginUser(input: ShouldLoginInput): Promise<boolean> {
    return await this.accountService.shouldLoginUser(input)
  }

  @loader(LoaderType.BLOCKING)
  async socialAuth(
    authInfo: SocialAuthProviderInput,
    privacyConsent?: PrivacyTermsConsent,
    shouldEnterQuiz?: boolean,
  ): Promise<void> {
    const { account, ui, userDevice, quiz, cart } = getState()
    const publicKey = await sessionSigningService.getPublicKey()

    const input: SocialAuthInput = {
      id: authInfo.id,
      email: authInfo.email || '',
      pw: authInfo.pw,
      token: authInfo.token,
      name1: authInfo.name,
      name2: authInfo.lastName,
      plan: this.cartService.getSubscriptionType(),
      lang: ui.lang,
      userDevice,
      appConsent: true,
      goal: goalMap[quiz.data?.fertilityGoal!] || account.goal,
      marketingConsent: privacyConsent?.marketingConsent,
      marketingSocialConsent: privacyConsent?.marketingSocialConsent,
      marketingSocialConsentTo: privacyConsent?.marketingSocialConsentTo,
      marketingSocialConsentKey: privacyConsent?.marketingSocialConsentKey,
      marketingSocialConsentFromATT: privacyConsent?.marketingSocialConsentFromATT,
      allowToCreateNewAccount: authInfo.allowToCreateNewAccount,
      quizData: quiz.data || undefined,
      publicKey,
      hwId: cart.hwId,
      discountCode: cart.discount?.code,
    }

    const response = await (authInfo.loginProvider === LoginProvider.APPLE
      ? this.accountService.appleAuth(input)
      : this.accountService.googleAuth(input))

    await this.notificationService.openNotificationsModal()

    await this.handleLogin(response, shouldEnterQuiz)
  }

  public async resetPassword(email: string): Promise<void> {
    await this.accountService.resetPassword({ email })
    this.snackbarService.showSnackbar(SNACKBAR.RESET_PASSWORD_EMAIL)
  }

  public async forgotPassword(email: string): Promise<void> {
    const modal = await this.popupController.presentModalAsAlert(
      {
        component: ForgotPasswordModal,
        componentProps: {
          email,
        },
        cssClass: ['modal--transparent'],
      },
      'alert-auth-wrongPassword',
      Priority.LOW,
    )

    const { data } = await modal.onDidDismiss()
    if (data?.email) {
      await this.resetPassword(data.email)
    }
  }

  public async linkAccountWithPassword(
    handler: (params: { password: string }) => void,
    loginProvider: LoginProvider,
  ): Promise<void> {
    void this.popupController.presentAlert(
      {
        header: tr('auth-apple-existing-account-header'),
        message:
          loginProvider === LoginProvider.APPLE
            ? tr('auth-apple-existing-account-message')
            : tr('auth-google-existing-account-message'),
        buttons: [
          {
            text: tr('btn-cancel'),
            role: 'cancel',
          },
          {
            text: tr('btn-enable'),
            handler,
          },
        ],
        inputs: [
          {
            name: 'password',
            placeholder: 'Password',
            type: 'password',
          },
        ],
        backdropDismiss: false,
      },
      'alert-accountLink',
    )
  }

  public async handleLogin(response: BackendResponseFM, shouldEnterQuiz?: boolean): Promise<void> {
    const isRegistered = !!response.res?.isRegistered

    this.registrationAnalytics(isRegistered)

    if (isRegistered) {
      const ownsHw = this.quizService.ownsRecommendedDevice(getState().quiz)
      const verifyOnWeb = this.shouldVerifyEmailOnWeb()
      dispatch('extendUI', { sentEmailVerificationWithRedirect: verifyOnWeb })
      void this.accountService.sendVerificationEmail(verifyOnWeb, ownsHw)
    }

    const redirectTo = await this.getRegistrationRedirect(isRegistered, shouldEnterQuiz)

    await this.redirectIntended(redirectTo)
  }

  private registrationAnalytics(isRegistered: boolean): void {
    this.analyticsService.identify()

    if (isRegistered) {
      this.analyticsService.trackEvent(EVENT.REGISTRATION_START)
    }
  }

  private async redirectIntended(redirectTo?: string): Promise<void> {
    const { account, ui } = getState()

    if (account.lang !== ui.lang) {
      await this.langService.init()
    }

    if (redirectTo) {
      await this.navController.navigateRoot(redirectTo)
      return
    }

    await this.navController.navigateRoot(ROUTES.HomePage)
    void di.get(BootstrapService).initCompletedUser()
  }

  public reportDeviceSelected(productKey: ProductKey, ownsHw?: boolean): void {
    const { product } = getState()

    void this.mixpanelService.trackEvent(EVENT.DEVICE_SELECTED, {
      selectedMeasuringDevice: productKey,
      ownsDevice: ownsHw,
      availableDevices: product.items
        .filter(item => measuringDeviceList.includes(item.key))
        .map(i => i.key),
    })
  }

  public shouldVerifyEmailOnWeb(): boolean {
    const { account, remoteConfig, sessionId, quiz } = getState()
    const isEmailHidden = account.email && accountSharedUtil.isEmailHidden(account.email)
    const deviceRecommendation = this.quizService.getRecommendedDevice(quiz.data, quiz.flow)

    return !!(
      remoteConfig.emailVerificationOnWebEnabled &&
      sessionId &&
      !isEmailHidden &&
      isIOSApp &&
      deviceRecommendation !== this.productService.getDefaultThermometer()
    )
  }

  private async getRegistrationRedirect(
    isRegistered?: boolean,
    shouldEnterQuiz?: boolean,
  ): Promise<string | undefined> {
    if (!isRegistered) return

    const { quiz } = getState()

    if (shouldEnterQuiz && !this.quizService.hasCompletedQuiz(quiz.data)) {
      const result = this.quizService.getEngine().getFirstPageInFlow(quiz.flow)

      return `${quizParentSlug}/${result}`
    }

    if (this.shouldVerifyEmailOnWeb()) {
      return ROUTES.VerifyEmailPage
    }

    if (this.ab322Service.shouldSyncOuraBeforePayment()) {
      return ROUTES.OuraAuthAB322Page
    }

    if (this.quizService.hasDeviceRecommendation(quiz)) {
      return ROUTES.PlansPage
    }

    return ROUTES.SignupMeasuringDevicePage
  }

  /**
   * Sets measuring device from quiz recommendation if there is a recommendation
   */
  public async setMeasuringdeviceFromQuiz(): Promise<void> {
    const { quiz } = getState()

    if (!this.quizService.hasDeviceRecommendation(quiz)) {
      return
    }

    const recommendedDevice = this.quizService.getRecommendedDevice(quiz.data, quiz.flow)
    const ownsHw = this.quizService.ownsRecommendedDevice(quiz)

    const cartItem: CartItemInput = {
      key: recommendedDevice,
      quantity: 1,
      variant: ownsHw ? ProductVariant.ownsDevice : ProductVariant.wantsDevice,
    }

    dispatch('setOwnsHw', ownsHw)

    const selectedHwId = hwIdByProductKey[recommendedDevice]
    _assert(selectedHwId, `hwId not found for productKey ${recommendedDevice}`)

    await this.cartService.update({
      items: [cartItem],
      hwId: selectedHwId,
    })

    this.reportDeviceSelected(recommendedDevice, ownsHw)
  }
}
