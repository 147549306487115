import { Component, Input, OnInit } from '@angular/core'
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms'
import { BaseModal } from '@app/pages/base.modal'
import { validatorsUtil } from '@app/util/validators.util'
import {
  IonButton,
  IonButtons,
  IonFooter,
  IonInput,
  IonItem,
  IonLabel,
} from '@ionic/angular/standalone'
import { MarkdownDirective } from '../../dir/markdown/markdown.directive'
import { TrPipe } from '../../pipes/tr.pipe'

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password.modal.html',
  styleUrls: ['./forgot-password.modal.scss'],
  imports: [
    IonItem,
    IonLabel,
    IonInput,
    ReactiveFormsModule,
    IonFooter,
    IonButtons,
    IonButton,
    MarkdownDirective,
    TrPipe,
  ],
})
export class ForgotPasswordModal extends BaseModal implements OnInit {
  public className = 'ForgotPasswordModal'

  @Input()
  public email = ''

  public emailControl = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required, validatorsUtil.email],
  })

  public ngOnInit(): void {
    this.emailControl.setValue(this.email)
  }

  public confirm(): void {
    void this.dismissModal({ email: this.emailControl.value })
  }
}
